<template>
  <div>
    <h5 class="info-text">Main informations</h5>
    <div class="md-layout-item md-size-100">
      <div class="md-layout">
        <div class="md-layout-item md-size-80 mt-4 md-small-size-100 mx-auto">
          <md-field :class="[
            {'md-valid': !errors.has('name') && touched.name},
            {'md-form-group': true},
            {'md-error': errors.has('name')}]">
            <md-icon>important_devices</md-icon>
            <label>Experiment name</label>
            <md-input
                v-model="name"
                data-vv-name="name"
                type="text"
                name="name"
                required
                v-validate="modelValidations.name">
            </md-input>
            <slide-y-down-transition>
              <md-icon class="error" v-show="errors.has('name')">close</md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon class="success" v-show="!errors.has('name') && touched.name">done</md-icon>
            </slide-y-down-transition>
          </md-field>
          <md-field :class="[{'md-form-group': true}]">
            <md-icon>chrome_reader_mode</md-icon>
            <label>Description</label>
            <md-input
                v-model="description"
                data-vv-name="description"
                type="text"
                name="description">
            </md-input>
          </md-field>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'

  export default {
    components: {
      SlideYDownTransition
    },
    data() {
      return {
        name: '',
        description: '',
        touched: {
          name: false
        },
        modelValidations: {
          name: {
            required: true
          }
        }
      }
    },
    methods: {
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      validate() {
        return this.$validator.validateAll().then(res => {
          if(res) {
            this.$emit('on-validated', {main: {name: this.name, description: this.description}}, true)
            return true
          }
          return res
        })
      }
    },
    watch: {
      name () {
        this.touched.name = true
      }
    }
  }
</script>
<style>
.center {
  text-align: center;
}
</style>
