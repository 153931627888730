<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-66 md-xsmall-size-80 mx-auto">
      <simple-wizard>
        <template slot="header">
          <h3 class="title">Build your new experiment</h3>
          <h5 class="category"></h5>
        </template>

        <wizard-tab :before-change="() => validate('step1')">
          <template slot="label">
            Customer
          </template>
          <first-step ref="step1" @on-validated="onStepValidated"></first-step>
        </wizard-tab>

        <wizard-tab :before-change="() => validate('nameStep')">
          <template slot="label">
            Name
          </template>
          <name-step ref="nameStep" @on-validated="onStepValidated"></name-step>
        </wizard-tab>

        <wizard-tab :before-change="() => validate('step2')">
          <template slot="label" >
            Tags
          </template>
          <second-step ref="step2" @on-validated="onStepValidated"></second-step>
        </wizard-tab>

        <wizard-tab :before-change="() => validate('step3')">
          <template slot="label">
            Template
          </template>
          <third-step ref="step3" @on-validated="wizardComplete"></third-step>
        </wizard-tab>
      </simple-wizard>
    </div>
  </div>
</template>
<script>
  import FirstStep from '../Forms/Wizard/FirstStep.vue'
  import NameStep from '../Forms/Wizard/NameStep.vue'
  import SecondStep from '../Forms/Wizard/SecondStep.vue'
  import ThirdStep from '../Forms/Wizard/ThirdStep.vue'
  import swal from 'sweetalert2'
  import {SimpleWizard, WizardTab} from '@/components'

  export default {
    data() {
      return {
        wizardModel: {}
      }
    },
    components: {
      FirstStep,
      NameStep,
      SecondStep,
      ThirdStep,
      SimpleWizard,
      WizardTab
    },
    methods: {
      validate(ref) {
        return this.$refs[ref].validate()
      },
      onStepValidated(model) {
        this.wizardModel = {...this.wizardModel, ...model}
      },
      wizardComplete(model) {
        this.onStepValidated(model)
        this.createExperiment()
      },
      createExperiment() {
        var self = this
        var formData = new FormData()
        formData.append('admin_id', localStorage.getItem('admin_id'))
        formData.append('customer_id', this.wizardModel.customer.id)
        formData.append('name', this.wizardModel.main.name)
        formData.append('description', this.wizardModel.main.description)
        formData.append('tags', this.wizardModel.tags)
        formData.append('template_id', 0)
        if(this.wizardModel.template.code) formData.append('code', this.wizardModel.template.code)
        var xhr = new XMLHttpRequest()
        xhr.open('POST', self.$root.apiUrl + 'v1/experiments')
        xhr.setRequestHeader('Authorization', localStorage.getItem('token'))
        xhr.onload = function () {
          if (xhr.status === 401) self.$router.push({name: 'Login'})
          var response = JSON.parse(xhr.responseText)
          if (xhr.status === 201) {
            self.showSweetAlertSuccess(response.response.id)
          } else {
            var message
            if (response.response && response.response[0] && response.response[0].message)
              message = response.response[0].message
            else
              message = 'Unknown error'
            self.showSweetAlertError(message)
          }
        }
        xhr.send(formData)
      },
      showSweetAlertSuccess(id) {
        swal({
          title: 'Good job!',
          text: 'You created new experiment!',
          type: 'success',
          showConfirmButton: true,
          showCancelButton: false,
          confirmButtonText: 'Go to editor',
          confirmButtonClass: 'md-button md-success',
          buttonsStyling: false
        }).then(() => {
          this.$router.push({name: 'Edit Experiment', params: {id: id}})
        })
      },
      showSweetAlertError (message) {
        swal({
          title: 'An error occured!',
          text: message,
          type: 'warning',
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'OK',
          cancelButtonClass: 'md-button md-success',
          buttonsStyling: false
        })
      },
    }
  }
</script>
